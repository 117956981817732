.DateRangePicker_picker {
  @apply rounded;
  transform: translateY(-18px);
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.07),
    0px 1.6711px 18.7999px rgba(0, 0, 0, 0.0503198),
    0px 0.893452px 10.0513px rgba(0, 0, 0, 0.0417275),
    0px 0.500862px 5.6347px rgba(0, 0, 0, 0.035),
    0px 0.266004px 2.99255px rgba(0, 0, 0, 0.0282725),
    0px 0.11069px 1.24527px rgba(0, 0, 0, 0.0196802);
}

.DayPicker {
  @apply rounded;
  @apply shadow-none;
}

.DayPicker_weekHeader_li {
  @apply text-ink-not-as-dark;
}

.CalendarMonth_caption strong {
  @apply text-preset-5 text-ink-dark font-medium;
}

.CalendarMonth_table {
  @apply border-separate;
  border-spacing: 0 4px;
}

.CalendarDay {
  @apply border-none;
}

.CalendarDay__selected,
.CalendarDay__selected:hover {
  @apply rounded;
  @apply bg-brand-50;
  @apply text-ink-clear;
  @apply border-brand-50;
}

.CalendarDay__selected_span,
.CalendarDay__selected_span,
.CalendarDay__selected_span:hover {
  @apply bg-status-positive-light;
  @apply text-ink-dark;
  @apply border-none;
}
