.rightbordershadow::after {
  content: '';
  display: block;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAABCAYAAADn9T9+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAnSURBVHgBY3j37t1/EGZgYJDj4uKSAtJiQCwExPxAzK2iosIOpFkAJLEIorjytVYAAAAASUVORK5CYII=');
  width: 10px;
  height: 100%;
  position: absolute;
  right: -10px;
  top: 0;
  margin-left: 20px;
}
