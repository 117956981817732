.SingleDatePicker .SingleDatePickerInput {
  @apply rounded;
  @apply border border-solid border-support-line-darker;
  @apply pl-1;
}

.SingleDatePicker .DateInput,
.SingleDatePicker .DateInput_input {
  @apply border-none;
  background: transparent;
  width: 100px;
}

.SingleDatePicker .DateInput > svg {
  @apply hidden;
}

.SingleDatePicker .DateInput input {
  @apply text-preset-5 text-center font-sans font-medium not-italic;
  @apply pl-0 pr-0;
}

.SingleDatePicker .DateInput input:focus {
  --tw-ring-shadow: none;
}

.SingleDatePicker .SingleDatePicker_picker {
  @apply rounded;
  transform: translateY(-18px);
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.07),
    0px 1.6711px 18.7999px rgba(0, 0, 0, 0.0503198),
    0px 0.893452px 10.0513px rgba(0, 0, 0, 0.0417275),
    0px 0.500862px 5.6347px rgba(0, 0, 0, 0.035),
    0px 0.266004px 2.99255px rgba(0, 0, 0, 0.0282725),
    0px 0.11069px 1.24527px rgba(0, 0, 0, 0.0196802);
}

.SingleDatePicker .DayPicker {
  @apply rounded;
  @apply shadow-none;
}

.SingleDatePicker .SingleDatePickerInput_calendarIcon {
  @apply pt-3;
}

.SingleDatePicker .DayPicker_weekHeader_li {
  @apply text-ink-not-as-dark;
}

.SingleDatePicker .CalendarMonth_caption strong {
  @apply text-preset-5 text-ink-dark font-medium;
}

.SingleDatePicker .CalendarMonth_table {
  @apply border-separate;
  border-spacing: 0 4px;
}

.SingleDatePicker .CalendarDay {
  @apply border-none;
}

.SingleDatePicker .CalendarDay__selected {
  @apply rounded;
  @apply bg-brand-50;
  @apply text-ink-clear;
}

.SingleDatePicker .CalendarDay__selected_span,
.SingleDatePicker .CalendarDay__hovered_span,
.SingleDatePicker .CalendarDay__hovered_span:hover {
  @apply bg-status-positive-light;
  @apply text-ink-dark;
  @apply border-none;
}
