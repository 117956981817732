@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .border-3 {
    border-width: 3px;
  }
  .partial-check {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3 8C3 7.66667 3.2 7 4 7H12C12.8 7 13 7.66667 13 8C13 8.33333 12.8 9 12 9H4C3.2 9 3 8.33333 3 8Z'/%3e%3c/svg%3e");
  }
  .clamp-2-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .clamp-3-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .slider {
    -webkit-appearance: none;
  }
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 25px;
    width: 25px;
    background: #fff;
    cursor: pointer;
    border-radius: 50%;
    border: 3px solid #045554;
    opacity: 1;
  }
}
.slider::-moz-range-thumb {
  background: #fff;
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid #045554;
  opacity: 1;
}

::selection {
  color: #fff;
  background: #045445; /* WebKit/Blink Browsers */
}

input:checked ~ .dot {
  transform: translateX(100%);
}
