.rc-time-picker {
  @apply align-middle;
  @apply w-40 py-1;
  @apply rounded;
  @apply border border-solid border-support-line-darker;
}

.rc-time-picker input {
  @apply text-preset-5 font-sans font-medium not-italic text-ink-dark;
  @apply border-none;
}

.rc-time-picker input:focus-within {
  border-color: var(--color-ink-primary);
  box-shadow: inset 0 0 0 1px var(--color-primary);
}
